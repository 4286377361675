import { intl } from '@apps/common/shared/lib/intl';
import { modalManagerModel } from '@apps/common/services/modal-manager';
import { StartStudentFlowModel } from '@apps/common/features/start-student-flow';
import { applyOffer } from '@common/components/Root/actions';
import { findMetric } from '@common/utils/Analytics/dictionary';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { api } from '../api';
import { showVerificationSubscriptionModal } from '../ui';
import { messages } from '../lib/messages';
import { BANNER_CODE } from '../lib/consts';
import { StudentApi, StudentModel } from '../../student';
import { changeChoiceSubscriptionModalLoader } from '../ui/modals/choice-subscription/model/choice-subscription-modal-loader';
import { $verificationStatus } from './verification-status';
import { redirectToVkVerifications, getOffer, checkIsIos } from './actions';
import { IS_MINIAPP } from '@apps/common/consts';
import apiVkBridge from '@apps/common/utils/ApiVkBridge';
const { closeModal } = modalManagerModel;
const getOfferData = async (params) => {
    const { offerKey } = params;
    const offer = await getOffer({ offerKey });
    const correlationId = offer?.correlationId || '';
    const widget = offer?.widgets.trial || offer?.widgets.offer;
    const button = widget?.buttons[0] || { type: 'purchase' };
    const analyticClick = findMetric(widget?.buttons[0]?.metrics, 'click')?.value || 'reject-modal_verification_subscription-button';
    const analyticShowAttribute = findMetric(widget?.metrics, 'show')?.value || 'reject-modal_verification_subscription';
    const applyOfferHandler = async () => {
        await applyOffer({
            correlationId,
            button,
        });
    };
    return {
        analyticShowAttribute,
        description: widget?.description || '',
        applyButton: {
            analyticClick,
            handler: applyOfferHandler,
            mode: 'primary',
            children: widget?.buttons[0]?.name || '',
        },
    };
};
const idleStep = () => {
    showVerificationSubscriptionModal({
        status: 'idle',
        header: intl.formatMessage(messages[`verificationSubscriptionModalHeader__idle`]),
        subheader: intl.formatMessage(messages[`verificationSubscriptionModalSubheader__idle`]),
        buttons: {
            firstButton: {
                analyticClick: 'checking-modal_verification_subscription-button_confirm',
                handler: async () => {
                    closeModal();
                    api.bannerEnabled({ bannerCode: BANNER_CODE });
                    await redirectToVkVerifications();
                },
                mode: 'primary',
                children: 'Подтвердить',
            },
            secondButton: {
                analyticClick: 'checking-modal_verification_subscription-button_cancel',
                handler: () => closeModal(),
                mode: 'secondary',
                children: 'Отмена',
            },
        },
    });
};
const errorStep = () => {
    showVerificationSubscriptionModal({
        status: 'error',
        header: intl.formatMessage(messages[`verificationSubscriptionModalHeader__error`]),
        subheader: intl.formatMessage(messages[`verificationSubscriptionModalSubheader__error`]),
        buttons: {
            firstButton: { analyticClick: '', handler: () => closeModal(), mode: 'primary', children: 'Понятно' },
        },
    });
};
const iosStep = () => {
    showVerificationSubscriptionModal({
        status: 'completed',
        header: intl.formatMessage(messages[`verificationSubscriptionModalHeader__completedIos`]),
        subheader: intl.formatMessage(messages[`verificationSubscriptionModalSubheader__completedIos`]),
        buttons: {
            firstButton: { analyticClick: '', handler: () => closeModal(), mode: 'primary', children: 'Понятно' },
        },
    });
};
const payCompletedStep = async () => {
    try {
        if (IS_MINIAPP && (await apiVkBridge.getClientVersion()).platform === 'ios') {
            showVerificationSubscriptionModal({
                analyticShowAttribute: 'completed-modal_verification_subscription-button_ios',
                status: 'completed',
                header: intl.formatMessage(messages[`verificationSubscriptionModalHeader__completedIos`]),
                subheader: intl.formatMessage(messages[`verificationSubscriptionModalSubheader__completedIos`]),
                buttons: {
                    firstButton: {
                        analyticClick: 'completed-modal_verification_subscription-button_ios',
                        handler: modalManagerModel.closeModal,
                        mode: 'primary',
                        children: 'Понятно',
                    },
                },
            });
            return;
        }
        const { applyButton, analyticShowAttribute } = await getOfferData({ offerKey: 'youngSubscription' });
        showVerificationSubscriptionModal({
            analyticShowAttribute,
            status: 'completed',
            header: intl.formatMessage(messages[`verificationSubscriptionModalHeader__completed`]),
            subheader: intl.formatMessage(messages[`verificationSubscriptionModalSubheader__completed`]),
            buttons: {
                firstButton: applyButton,
            },
        });
    }
    catch {
        errorStep();
    }
};
const payFailedStep = async () => {
    try {
        const studentStatus = StudentModel.$status.getState();
        const hasActiveSubscription = SubscriptionsModel.$hasActiveSubscription.getState();
        const { applyButton, analyticShowAttribute, description } = await getOfferData({ offerKey: 'youngRejected' });
        const closeButton = {
            analyticClick: 'reject-modal_verification_subscription-button_close',
            handler: () => closeModal(),
            mode: 'primary',
            children: 'Понятно',
        };
        const getDescription = () => {
            switch (true) {
                case hasActiveSubscription: {
                    return intl.formatMessage(messages['verificationSubscriptionModalSubheader__failed']);
                }
                case !!description: {
                    return description;
                }
                default: {
                    return intl.formatMessage(messages['verificationSubscriptionModalSubheader__failed']);
                }
            }
        };
        showVerificationSubscriptionModal({
            analyticShowAttribute,
            status: 'failed',
            header: intl.formatMessage(messages[`verificationSubscriptionModalHeader__failed`]),
            subheader: getDescription(),
            buttons: {
                firstButton: hasActiveSubscription ? closeButton : applyButton,
                secondButton: studentStatus === 'REJECTED'
                    ? undefined
                    : {
                        analyticClick: 'reject-modal_verification_subscription-button_student',
                        handler: async () => {
                            await StartStudentFlowModel.startFlow({ skipYoungFlow: true });
                        },
                        mode: 'secondary',
                        children: 'Подать заявку как студент',
                    },
            },
        });
    }
    catch {
        errorStep();
    }
};
export const youngSubscriptionFlow = async () => {
    await StudentApi.getStudentsStatusFx();
    const status = $verificationStatus.getState();
    changeChoiceSubscriptionModalLoader(true);
    switch (status) {
        case 'idle': {
            idleStep();
            break;
        }
        case 'completed': {
            const isIos = await checkIsIos();
            if (isIos) {
                iosStep();
                break;
            }
            payCompletedStep();
            break;
        }
        case 'failed': {
            payFailedStep();
        }
    }
    changeChoiceSubscriptionModalLoader(false);
};
